<template>
  <v-dialog
    v-model="isUploadProductImageActive"
    max-width="350"
    persistent
  >
    <v-card

      color="secondary"
    >
      <v-card-title class="justify-center text-h5 subheading font-weight-bold white--text">
        Ürün Resmi Yükle
      </v-card-title>
      <v-card-text class="text-center">
        <image-uploader
          :max-width="512"
          :quality="0.7"
          :auto-rotate="true"
          output-format="file"
          :preview="true"
          :class-name="['d-none']"
          accept="video/*,image/*"
          do-not-resize="['gif', 'svg']"
          @input="setImage"
          @onUpload="onUpload"
          @onComplete="endImageResize"
        >
          <label

            slot="upload-label"
            for="fileInput"
          >
            <v-icon
              v-if="!hasImage"
              size="100"
              style="cursor: pointer;"
            >
              {{ icons.mdiCloudUpload }}
            </v-icon>
            <div
              v-else-if="hasImage && !loading"
              style="cursor: pointer;"
              class="base rounded  pa-2 d-flex align-center justify-center"
            >
              <v-icon color="white">
                {{ icons.mdiRefresh }}
              </v-icon> <div class="ml-3 white--text">Değiştir</div>
            </div>

          </label>
        </image-uploader>
      </v-card-text>

      <v-card-actions>
        <v-spacer v-if="!hasImage" />
        <v-btn
          small
          text
          :disabled="loading"
          @click="close()"
        >
          Vazgeç
        </v-btn>
        <v-spacer v-if="hasImage" />
        <v-btn
          v-if="hasImage"
          color="tertiary"
          depressed
          :loading="loading"
          @click="save()"
        >
          Yükle
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import store from '@/store'
import { mdiCloudUpload, mdiRefresh } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ImageUploader from 'vue-image-upload-resize'

export default {
  components: {
    ImageUploader,
  },

  model: {
    prop: 'isUploadProductImageActive',
    event: 'update:is-upload-product-image-active',
  },
  props: {
    isUploadProductImageActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isUploadImageModul: {
      type: String,
      required: true      
    },
    isImageSize: {
      type: Array,
      required: true      
    },
  },
  setup(props, { emit }) {
    const hasImage = ref(false)
    const loading = ref(false)

    const image = ref(null)
    const setImage = file => {
      hasImage.value = true
      image.value = file
    }
    const onUpload = () => {
      document.querySelector('.img-preview').classList.remove('d-none')
    }

    const endImageResize = () => {}

    const close = () => {
      hasImage.value = false
      loading.value = false
      image.value = null
      document.querySelector('.img-preview').classList.add('d-none')
      emit('update:is-upload-product-image-active', false)
    }
    const save = () => {
      loading.value = true
      const formData = new FormData()
      formData.append('method', 'addMedia')
      formData.append('realfilename', image.value.name)
      //formData.append('modul', 'urunler')
      formData.append('modul', props.isUploadImageModul)
      formData.append('seq', 1)
      formData.append('file', image.value)
      formData.append('localid', props.data.id)
      formData.append('sizes', JSON.stringify(props.isImageSize[0])
     /*  formData.append(
        'sizes',
        JSON.stringify({
          bresim: 900,
          dresim: 600,
          oresim: 450,
          kresim: 50,
        }),*/
      ) 
      store.dispatch('uploadMethod', formData).then(response => {
        if (response.error === 0) {
          close()
          emit('refetch-data')
          emit('save-image-event', {
            message: 'Resim Eklendi',
            color: 'info',
            timeout: 5000, 
          })
        } else {
          loading.value = false
        }
      })
    }

    return {
      hasImage,
      image,
      setImage,
      endImageResize,
      close,
      loading,
      onUpload,
      save,
      icons: {
        mdiCloudUpload,
        mdiRefresh,
      },
    }
  },
}
</script>
<style lang="scss">
.img-preview {
  border-radius: 4px;
  width: 100%;
}
</style>
